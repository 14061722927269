/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { SET_CURRENT_RUN } from '../actions/modelValidation';
const state = {
  runConfig: {},
};

const getters = {
  runConfig: (state) => state.runConfig,
};

const actions = {
  [SET_CURRENT_RUN]: ({ commit }, currentRun) =>
      commit(SET_CURRENT_RUN, currentRun)
    ,
};

const mutations = {
  [SET_CURRENT_RUN]: (state, data) => {
    state.runConfig = data
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};

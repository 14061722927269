import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const isAuthenticated = store.getters['isAuthenticated'];

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
    },
    {
        path: '/',
        redirect: '/previousRuns'
    },
    // {
    //     path: '/new-run',
    //     name: 'newRun',
    //     component: () => import(/* webpackChunkName: "NewRun" */ '@/views/NewRunView.vue'),
    // },
    {
        path: '/previous-runs',
        name: 'previousRuns',
        component: () => import(/* webpackChunkName: "previousRuns" */ '@/views/PreviousRunsView.vue'),
    },
    {
        path: '/run-results',
        name: 'RunResults',
        component: () => import(/* webpackChunkName: "RunResults" */ '@/views/RunResultsView.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    if (!isAuthenticated && to.name !== 'login') {
        return { name: 'login' };
    }
    if (isAuthenticated && (to.name === 'login' || to.path === '/')) {
        return { name: 'previousRuns' };
    }
});

export default router;

import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import createPersistedState from "vuex-persistedstate";
import modelValidation from './modules/modelValidation';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, modelValidation },
  plugins: [createPersistedState()],
});

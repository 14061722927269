import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueCookies from "vue-cookies";
import modelValidationClient from './services/modelValidationClient';
import ToastPlugin from 'vue-toast-notification';

import "typeface-roboto/index.css";
import "@mdi/font/css/materialdesignicons.css";
import 'vue-toast-notification/dist/theme-bootstrap.css';

const plugins = [store, router, vuetify, VueCookies, ToastPlugin];

const app = createApp(App);

app.config.globalProperties.$modelValidationClient = modelValidationClient;

plugins.forEach((plugin) => app.use(plugin));

app.config.globalProperties.$showNotification = function(message, type) {
  this.$toast.open({
      position: 'bottom',
      message: message,
      type: type,
  });
};

app.mount("#app");

<template>
  <v-app>
    <TopNavbar />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import TopNavbar from "./components/TopNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  components: {
    TopNavbar,
    AppFooter,
  },
};
</script>

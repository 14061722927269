<template>
    <div>
        <v-app-bar app dense color="primary" dark :clipped-left="true">
            <v-toolbar-title style="cursor: default">
                Fincom Model Validation
            </v-toolbar-title>
            <!-- <div class="flex-grow-1"></div>
            <v-chip outlined v-if="userName" to="/profile" prepend-icon="mdi-account">
                {{ userName }}
            </v-chip> -->
            <v-icon v-if="userRole" @click="doLogout" size="30" class="mx-3">mdi-logout</v-icon>
        </v-app-bar>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import MainDrawer from '@/components/menu/MainDrawer';

export default {
    components: {
        MainDrawer,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'userName', 'userRole']),
    },
    watch: {
        isAuthenticated(newValue) {
            if (!newValue) {
                this.$router.go({ name: 'login' });
            }
        },
    },
    methods: {
        ...mapActions({ logout: AUTH_LOGOUT }),
        updateToggle(value) {
            this.isDrawerOpen = value;
        },
        async doLogout() {
            const username = localStorage.getItem('user-name')
            await this.logout(username);
            this.$router.go({ name: 'login' });
        }
    },
};
</script>

import BaseAPIClient from "@/services/baseClient";
import router from '@/router';

export class ModelValidationClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.baseURL =process.env.NODE_ENV === 'production' ? '/api/v1' : process.env.VUE_APP_MODEL_VALIDATION_API_URI
    this.instance.defaults.baseURL = this.baseURL;

    this.instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          // Clear localStorage
          localStorage.clear();
          router.go({ name: 'login'})
        }
        return Promise.reject(error);
      }
    );
  }
  

  // POST

  // login
  async login({username, password}) {
    if (typeof numberOfNames === 'string') {
      numberOfNames = parseInt(numberOfNames)
    }

    if (typeof totalNumberOfNames === 'string') {
      totalNumberOfNames = parseInt(totalNumberOfNames)
    }
    return this.instance.post(`/login`, {username, password});
  }

  // new-configuration
  // Generate a new document with the data sent (names, cases)
  async postConfiguration({totalNumberOfNames, numberOfNames, cases, tenantId, userId}) {
    if (typeof numberOfNames === 'string') {
      numberOfNames = parseInt(numberOfNames)
    }

    if (typeof totalNumberOfNames === 'string') {
      totalNumberOfNames = parseInt(totalNumberOfNames)
    }
    return this.instance.post(`/new-configuration`, { totalNumberOfNames, numberOfNames, cases, tenantId, userId });
  }

  // upload-bank-results-file
  async newFile({ _id, file, description }) {
    const formData = new FormData();
    const uploadedBy = localStorage.getItem('user-name');
    const tenantId = localStorage.getItem('tenantId');

    formData.append('file', file);
    formData.append('description', description);
    formData.append('type', 'model-validation');
    formData.append('configsId', _id);
    formData.append('uploadedBy', uploadedBy);
    formData.append('tenantId', tenantId);

    return this.instance.post('/upload-bank-results-file', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async runCompareWithExistingFile(configsId, fileData) {
    return this.instance.post(`/compare-previousFile/${configsId}`, {...fileData});
  }

  // PUT
  async updateStatus(_id, status) {
    return this.instance.put(`/update-status/${_id}?status=${status}`);
    
  }
  
  // GET

  // test
  async test() {
    return this.instance.get('/',);
  }

  // cases-list
  // Returns all the cases we can compare by.
  async getCasesList() {
    return this.instance.get(`/cases-list`);
  }
  
  // random-names
  // Returns a list of random names.
  // async getRandomNames(count) {
  //   return this.instance.get(`${this.modelValidationRoute}/random-names/${count}`);
  // }
  
  // names/:runId
  // Returns a list of names by run _id
  // async getNamesByRunId(_id) {
  //   return this.instance.get(`/names/${_id}`);
  // }
  
  // previous-configurations/:tid
  // Returns a list of previous runs by tenantId
  async getPreviousConfigurationsByTenantId({tenantId, page, itemsPerPage, sortBy, sortOrder}) {
    let requestString = `/previous-configurations/${tenantId}?page=${page}&itemsPerPage=${itemsPerPage}`
    if (sortBy) requestString += `&sortBy=${sortBy}&sortOrder=${sortOrder}`
    return this.instance.get(requestString);
  }

  // :runId/details
  // Returns more in depth data of a run by it's Id.
  async getRunDetails( _id ) {
    return this.instance.get(`/${_id}/details`);
  }
  
  // :runId/report
  // Get the information for the report to download by runId.
  // async getReportData( _id ) {
  //   return this.instance.get(`${this.modelValidationRoute}/${_id}/report`);
  // }
  
  // fincom-results/:runId
  // Returns the data from the fincom run by runId.
  async downloadLatestResults(fileId) {
    return this.instance.get(`/fincom-results/${fileId}`);
  }

  // DELETE
  // logout
  logout = async (username) => {
    const userToken = localStorage.getItem('user-token');
    if (userToken) {
      const headers = {};
      headers['x-access-token'] = userToken;
      
      return await this.instance.post('/logout', { username }, { headers })
    }
  }
  
  
}

export default new ModelValidationClient();

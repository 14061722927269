/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import modelValidationClient from '@/services/modelValidationClient';

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "../actions/auth";

const state = {
  token: localStorage.getItem("user-token") || "",
  role: localStorage.getItem("user-role") || "",
  userName: localStorage.getItem("user-name") || "",
  userId: localStorage.getItem("user-id") || "",
  tenantId: localStorage.getItem("tenantId") || "",
  userProfile: null,
  status: "",
  hasLoadedOnce: false,
  errors: null,
  managerId: "",
};

const getters = {
  isAuthenticated: (state) =>
    !!(
      state.token &&
      state.token !== "undefined" &&
      state.token !== "" &&
      state.token !== null
    ),
  authStatus: (state) => state.status,
  hasError: (state) => state.status === "error",
  userRole: (state) => state.role,
  userName: (state) => state.userName,
  userId: (state) => state.userId,
  tenantId: (state) => state.tenantId,
  managerId: (state) => state.managerId,
  userGroup: (state) => state.userGroup,
  errors: (state) => state.errors,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) =>
    new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      modelValidationClient
        .login(user)
        .then((resp) => {
          const resultData = resp.data;
          commit(AUTH_SUCCESS, resultData);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    }),
  // eslint-disable-next-line no-unused-vars
  [AUTH_LOGOUT]: async ({ commit }, userName) => {
    await modelValidationClient.logout(userName)
    localStorage.clear();
    commit(AUTH_LOGOUT);
  }
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    localStorage.setItem("user-token", resp.token);
    localStorage.setItem("user-role", resp.user.Role || resp.user.role);
    localStorage.setItem("user-name", resp.user.Username || resp.user.Username);
    localStorage.setItem("user-id", resp.user.ID || resp.user._id);
    localStorage.setItem("tenantId", resp.user.TenantId || resp.user.tenantId);

    state.status = "success";
    state.token = resp.token;
    state.role = resp.role;
    state.userName = resp.username;
    state.userProfile = resp;
    state.hasLoadedOnce = true;
    state.tenantId = resp.tenantId;
  },
  [AUTH_ERROR]: (state, err) => {
    state.status = "error";
    state.errors = err.message;
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.userProfile = null;
    state.token = "";
    state.role = "";
    state.userName = "";
    state.status = "";
    state.tenantId = "";
    state.errors = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
    <div class="bottom-navigation-container" v-if="userName">
      <v-bottom-navigation 
        grow 
        v-model="value" 
        color="primary" 
        :elevation="6"
    >
        <!-- <v-btn :to="{ name: 'newRun' }">
          <v-icon>mdi-new-box</v-icon>
          New Run
        </v-btn> -->
  
        <v-btn :to="{ name: 'previousRuns' }">
          <v-icon>mdi-play-box-multiple</v-icon>
          Previous Runs
        </v-btn>
      </v-bottom-navigation>
    </div>
  </template>
  
  
<script>
export default {
    data: () => ({ value: 1, userName: '' }),
    created () {
        this.userName = this.$store.getters.userName;
    },
};
</script>
  
<style scoped>
.bottom-navigation-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 55px; /* Set the desired height */
}
</style>